import React from 'react'
import ReactDOM from 'react-dom'
import { Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { QueryClient, QueryClientProvider } from 'react-query'

import { rootRoutes } from 'root-router'
import { ThemeProvider, theme, GlobalStyles } from 'ui/styles'
import { Sidebar } from 'app/features'

import { DimensionsProvider } from 'shared/dimensions'

import { Provider as ReduxProvider } from 'react-redux'
import { configureStore } from 'configure-store'
import { ConnectedRouter } from 'connected-react-router'
import { UnderMaintenance } from 'app/pages'

const history = createBrowserHistory()
const store = configureStore(history)

const queryClient = new QueryClient()

const Root: React.FC = () => (
  <ReduxProvider store={store}>
    <QueryClientProvider client={queryClient}>
      <DimensionsProvider>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <ConnectedRouter history={history}>
            {process.env.REACT_APP_UNDER_MAINTENANCE === 'true' ? (
              <UnderMaintenance />
            ) : (
              <>
                <Sidebar />
                <Switch>{rootRoutes()}</Switch>
              </>
            )}
          </ConnectedRouter>
        </ThemeProvider>
      </DimensionsProvider>
    </QueryClientProvider>
  </ReduxProvider>
)

ReactDOM.render(<Root />, document.getElementById('root'))
