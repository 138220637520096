import React from 'react'
import { styled } from 'ui/styles'
import { Image, Text } from 'ui/components'
import { breakpoints } from 'shared/dimensions'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 56px;
    width: 100vw;
    height: 100vh;
`

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 56px;
    margin-top: 100px;

    @media (max-width: ${breakpoints.mobile - 1}px) {
        width: 90%;
    }
`

const ContactWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
`

export const UnderMaintenance: React.FC = () => {
  return (
    <Wrapper>
      <ContentWrapper>
        <Image name={'frame'} width={'90%'}/>
        <Text variant={'h4'} align={'center'}>Сайт на техническом обслуживании</Text>
        <ContactWrapper>
          <Text variant={'t1'}>По вопросам сотрудничества:</Text>
          <Text variant={'h4'}>sales@codeitup.ru</Text>
        </ContactWrapper>
      </ContentWrapper>
      <Image name={'union'} width={'100%'}/>
    </Wrapper>
  )
}
